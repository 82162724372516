
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'List'
})
export default class List extends Vue {
  private searchInfo: {projectName: string} = {
    projectName: ''
  }

  private tableData = {
    tr: [
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '160',
        showOverflowTooltip: true
      },
      {
        label: '设备总数',
        prop: 'totalAmount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '在线设备数',
        prop: 'onlineAmount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '离线设备数',
        prop: 'offLineAmount',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private loading = false
  page = 1
  size = 10
  total = 0

  created (): void {
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get(this.$apis.trash.selectSumProjectList,
      {
        projectName: this.searchInfo.projectName,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'trashDetailMap',
      params: { projectId: id }
    })
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }
}
